@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@mixin nav-menu-item {
  display: block;
  line-height: 1em;
  padding: 25px 20px 16px 20px;
  font-size: 15px;
  font-weight: 500;
  color: $gray-300;
  position: relative;

  &:before {
    font-size: 20px;
    vertical-align: middle;
    line-height: 1;
    margin-right: 20px;
  }

  &.active,
  &:hover {
    text-decoration: none;
    border-left-color: $cs_blue;
    color: $cs_blue;
  }

  &:hover {
    color: $primary;
    border-left-color: $primary;
  }
}

.primary-navigation {
  padding-top: 20px;

  a {
    @include nav-menu-item;
    border-left: 4px solid transparent;
    border-top: 1px solid $gray-200;

    // &:before {
    // 	content: ' ';
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	right: 0;
    // 	border-top: 1px solid $gray-200;
    // }

    &:first-child {
      border-top: none;
    }
  }

  button {
    @include nav-menu-item;
    width: 100%;
    background: none;
    text-align: left;
    border: none;
    border-left: 4px solid transparent;
    border-top: 1px solid $gray-200;
  }

  // Extra links such as Logout.
  &__bottom {
    position: absolute;
    bottom: 0;
    width: $sidebar_width_xl;
    // width: ($sidebar_width_xl - 2); // overlap the scrollbar
    min-height: $sidebar-bottom-height;
    margin-top: $sidebar-bottom-margin-top;
    padding-bottom: 10px;

    // SVG waves background.
    img[class^='wave--'] {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    .wave--1 {
      z-index: 3;
    }

    .wave--2 {
      z-index: 2;
    }

    .wave--3 {
      z-index: 1;
    }

    .logout-button {
      position: relative;
      z-index: 10;
      color: #fff;
      border: none;
      background: none;
      font-weight: 500;
      line-height: 1em;
      padding: 25px 20px;
      display: block;
      margin: 0;

      &:before {
        font-size: 20px;
        vertical-align: middle;
        line-height: 1;
        margin-right: 20px;
      }
    }
  }
}
