@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.contract-details {
	@include make-col-ready();
	@include media-breakpoint-up(lg) {
		@include make-col(6);
		@include make-col-offset(3);
		padding-top: 60px;
	}

	&__back {
		@include make-col-ready();

		@include media-breakpoint-up(md) {
			@include make-col(3);
		}
	}

	&__heading {
		@include make-col-ready();

		@include media-breakpoint-up(md) {
			@include make-col(6);
			text-align: center;
			margin-bottom: 40px;
		}
	}

	&__data {
		border-top: 1px solid $gray-200;
		padding-top: $grid-gutter-width;
		margin: 0;
		font-size: 14px;
		color: $gray-300;

		// The <dl>
		&__list {
			margin-top: $grid-gutter-width;

			dt,
			dd {
				@include make-col(6);
				line-height: 1.25;
				padding: 20px;
				padding-bottom: calc(20px - 0.75em);
			}

			dt {
				color: $cs_blue;
				font-weight: 600;
				background: $gray-200;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 20px;
					right: 20px;
					border-top: 1px solid transparentize(#80808f, 0.8);
				}

				&:first-of-type:before {
					border-top: none;
				}
			}

			dd {
				margin-top: 1px;
				padding-left: 40px;
			}
		}

		.tooltip-wrapper {
			margin-right: 10px;
		}
	}

	&__download-button {
		padding-top: 10px !important;
	}
}
