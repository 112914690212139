@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.large-unit {
	width: 100%;
	max-width: 176px;
	text-align: center;
	margin: auto;
}

.order-replacement-parts__select-draws .small-unit-svg {
	width: 100%;
	height: 500px;

	&__box {
		&:hover {
			cursor: pointer;
		}

		&.active {
			fill: $primary;
		}
	}
}

.large-unit__box {
	padding: 10px 20px;
	border: 1px solid;
	border-top: 0;
	cursor: pointer;

	&:first-child {
		border-top: 1px solid;
	}

	&.active {
		background-color: $primary;
		color: #fff;
		border-color: black;
	}
}
