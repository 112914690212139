@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.input-password {
  display: block;
  position: relative; // For the meter.

  .label {
    display: block;
    margin-bottom: 7px;
  }

  input {
    padding: 15px;
    font-size: 14px;
    line-height: 1;
    height: auto;
    width: 100%;

    &:placeholder {
      color: #80808f;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      // border: 1px solid green;
      -webkit-text-fill-color: #000;

      // -webkit-box-shadow: 0 0 0px 1px #000 inset;
      // transition: background-color 1s ease-in-out 0s;
    }
  }

  .inline-error {
    font-size: 14px;
    padding-top: 8px;
    font-weight: 600;
    display: block;
    color: $danger;
  }

  // Visual password meter (coloured dots)
  &__meter {
    position: absolute;
    top: 10px;
    right: 0;

    .dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 4px;
      border-radius: 4px;
      background: $gray-100;
      transition: background 0.2s linear;
    }

    &__label {
      font-size: 12px;
      margin-bottom: 0;
      line-height: 1;
      min-height: 10px;
      text-align: center;
      width: 100%;
      color: $gray-400;
      display: block;
    }

    &--weak,
    &--medium,
    &--strong {
      span:nth-child(2) {
        background: #ff6357;
      }
    }

    &--medium,
    &--strong {
      span:nth-child(3) {
        background: #f3b71f;
      }
    }

    &--strong {
      span:nth-child(4) {
        background: $success;
      }
    }
  }
}
