@charset "UTF-8";

@font-face {
  font-family: 'cardssafe';
  src: url('fonts/icomoon.eot?vtii1l');
  src: url('fonts/icomoon.eot?vtii1l#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?vtii1l') format('truetype'),
    url('fonts/icomoon.woff?vtii1l') format('woff'),
    url('fonts/icomoon.svg?vtii1l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[data-icon]:before {
  font-family: 'cardssafe' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'cardssafe' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
Note that custom CSS rules must be provided when updating the font!
*/
.icon-wine-bar:before {
  content: '\4f';
}
.icon-automobile:before {
  content: '\51';
}
.icon-galleries-\&-museums:before {
  content: '\56';
}
.icon-night-club:before {
  content: '\57';
}
.icon-everything-else:before {
  content: '\46';
}
/*
Customisation end
*/

.icon-bicycle:before {
  content: '\e900';
}
.icon-lock-combination:before {
  content: '\62';
}
.icon-chart-line:before {
  content: '\61';
}
.icon-sort-amount:before {
  content: '\63';
}
.icon-phone:before {
  content: '\64';
}
.icon-call-phone:before {
  content: '\65';
}
.icon-mail:before {
  content: '\66';
}
.icon-home:before {
  content: '\67';
}
.icon-plus-round:before {
  content: '\68';
}
.icon-person-stalker:before {
  content: '\69';
}
.icon-briefcase:before {
  content: '\6a';
}
.icon-history:before {
  content: '\6b';
}
.icon-comments-o:before {
  content: '\6c';
}
.icon-arrow-swap:before {
  content: '\6d';
}
.icon-power:before {
  content: '\6e';
}
.icon-chevron-left:before {
  content: '\6f';
}
.icon-ios-arrow-back:before {
  content: '\70';
}
.icon-chevron-right:before {
  content: '\71';
}
.icon-ios-arrow-forward:before {
  content: '\72';
}
.icon-shopping-cart:before {
  content: '\73';
}
.icon-shopping-cart-1:before {
  content: '\74';
}
.icon-trash-bin:before {
  content: '\75';
}
.icon-exclamation-triangle:before {
  content: '\76';
}
.icon-thumbs-o-up:before {
  content: '\77';
}
.icon-thumbs-up:before {
  content: '\78';
}
.icon-building-o:before {
  content: '\79';
}
.icon-sticky-note-o:before {
  content: '\7a';
}
.icon-sticky-note:before {
  content: '\41';
}
.icon-compose:before {
  content: '\42';
}
.icon-clipboard:before {
  content: '\43';
}
.icon-info-circle:before {
  content: '\44';
}
.icon-information-circled:before {
  content: '\45';
}
.icon-ios-help:before {
  content: '\46';
}
.icon-check:before {
  content: '\47';
}
.icon-download:before {
  content: '\48';
}
.icon-cardssafe-box:before {
  content: '\49';
}
.icon-cardssafe-unit:before {
  content: '\4a';
}
.icon-cardssafe-key:before {
  content: '\4b';
}
.icon-thumbs-o-down:before {
  content: '\4c';
}
.icon-thumbs-down:before {
  content: '\4d';
}
.icon-times:before {
  content: '\4e';
}
.icon-wine-bar:before {
  content: '\4f';
}
.icon-swimming:before {
  content: '\50';
}
.icon-car:before {
  content: '\51';
}
.icon-football:before {
  content: '\52';
}
.icon-golf:before {
  content: '\53';
}
.icon-hotel:before {
  content: '\54';
}
.icon-icon-box:before {
  content: '\55';
}
.icon-museum:before {
  content: '\56';
}
.icon-night-club:before {
  content: '\57';
}
.icon-pub:before {
  content: '\58';
}
.icon-restaurant:before {
  content: '\59';
}
.icon-rugby:before {
  content: '\5a';
}
.icon-spa:before {
  content: '\30';
}
.icon-suitcase:before {
  content: '\31';
}
.icon-save-money:before {
  content: '\32';
}
.icon-graph-up:before {
  content: '\33';
}
.icon-graph-down:before {
  content: '\35';
}
.icon-pci:before {
  content: '\34';
}
