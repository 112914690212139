@import '../../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.select-venue {
	padding-top: 0px;

	@include media-breakpoint-up(sm) {
		padding-top: 100px;
	}

	&__title {
		font-size: 16px;

		@include media-breakpoint-up(sm) {
			font-size: 20px;
		}
	}

	&__options {
		padding-top: 30px;

		@include media-breakpoint-up(sm) {
			padding-top: 70px;
		}
	}

	&__option {
		// background: transparent;
		width: 100%;
		height: 250px;
		margin: 0 0 30px 0;
		display: inline-block;
		vertical-align: top;
		perspective: 600px;

		@include media-breakpoint-up(sm) {
			width: 320px;
			height: 270px;
			margin: 0 40px;
		}

		&.new-venue {
		}

		&.existing-venue {
		}
	}

	.flip-card {
		width: 100%;
		height: 100%;
		position: relative;
		transition: transform 0.6s;
		transform-style: preserve-3d;
		perspective: 600;
		// backface-visibility: hidden;

		&:hover {
			cursor: pointer;
		}

		&__content {
		}

		&__icon {
		}

		&__title {
			font-size: 16px;
		}

		&__button {
			border: 1px solid #fff;
			color: #fff;
			width: auto;
			text-align: center;
			background: none;
			font-size: 12px;
			font-weight: 600;
			padding: 10px;
			border-radius: 99px;
			min-width: 206px;
			line-height: 1;

			@include media-breakpoint-up(sm) {
				min-width: 206px;
			}
		}
	}

	.flip-card__face {
		position: absolute;
		height: 100%;
		width: 100%;
		// backface-visibility: ;
		// opacity: 0.5;
	}

	.flip-card__face--front {
		background: url('../../../assets/svg/venue-wave.svg') bottom left / 100% auto no-repeat,
			url('../../../assets/svg/venue-wave-2.svg') bottom left / 100% auto no-repeat;

		box-shadow: 1px 4px 10px 0 rgba(#000, 0.1);
		font-size: 16px;
		border-radius: 3px;
		backface-visibility: hidden;
		background-color: #fff;
	}

	.flip-card__face--back {
		@include gradient-x-three-colors($cs_gradient_1, $cs_gradient_2, 50%, $cs_gradient_3);
		box-shadow: 1px 4px 10px 0 rgba(#000, 0.1);
		transform: rotateY(180deg);
		padding: 10px;
		border-radius: 3px;

		@include media-breakpoint-up(sm) {
			padding: 20px;
		}

		.flip-card__face__content {
			background: transparentize(#fff, 0.9);
			border-radius: 3px;
		}
	}

	.flip .flip-card {
		transform: rotateY(180deg);
	}
}
