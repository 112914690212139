@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.small-unit {
	background: #fff;
	box-shadow: 0 10px 24px 0 rgba(#2d2f87, 0.07);
	padding: 30px 40px;
	display: flex;
	height: 100%;

	&__icon {
		padding: 0 30px 0 0;

		.small-unit-svg {
			height: 200px;
		}
	}

	&__data {
		@include make-col-ready();
		padding: 0;
		font-size: 12px;

		&__unit-id {
			color: $cs_blue;
			font-size: 16px;
			font-weight: 800;
			margin-bottom: 20px;
			line-height: 1;
			display: inline-block;
		}

		&__contract {
			display: inline-block;
			// float: right;
			color: $cs_blue;
		}

		&__keys {
			clear: both;
			color: $gray-400;
			display: block;
			padding-bottom: 20px;
			line-height: 1;
			border-bottom: 1px solid #ececee;
		}

		&__comments {
			color: $gray-400;
			text-decoration: underline;
			text-align: center;
			border: none;
			background: none;
			padding: 0;
			display: block;
			margin: auto;
			line-height: 1;
		}

		&__order-parts {
			border: 1px solid $primary;
			border-radius: 100px;
			font-weight: 800;
			text-align: center;
			display: block;
			padding: 10px;
			margin-top: 20px;
			transition: all 0.3s;

			&:hover {
				text-decoration: none;
				background: $primary;
				color: #fff;
			}
		}
	}
}
