@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.input-select {
  display: block;

  .label {
    display: block;
    margin-bottom: 7px;
  }

  .form-control {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 14px;
    height: 49px;
    // height: auto;
    width: 100%;

    &:placeholder {
      color: #80808f;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      // border: 1px solid green;
      -webkit-text-fill-color: #000;

      // -webkit-box-shadow: 0 0 0px 1px #000 inset;
      // transition: background-color 1s ease-in-out 0s;
    }
  }

  .inline-error {
    font-size: 14px;
    padding-top: 8px;
    font-weight: 600;
    display: block;
    color: $danger;
  }
}
