@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.app__main {
  // height: calc(100vh - #{$header_height});
  // overflow: auto;
  padding: 20px;
  transition: all 0.3s linear;

  @include media-breakpoint-up(md) {
    padding: 40px;
  }

  &.fetching {
    .lds-ellipsis {
      display: block;
    }
  }

  .loading-overlay {
    // Positioned to fill window, but under the fixed header.
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    z-index: 8888;
    display: none;

    &__icon {
      position: fixed;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      height: 74px;
      width: 74px;
      margin-left: -37px;
      margin-top: -37px;
      background: $primary;
      color: #fff;
      animation: rotate infinite 1.2s ease-in-out;
      // perspective: 4000;

      .icon {
        font-size: 40px;
        line-height: 1;
        margin-top: 15px;
        display: block;
      }
    }

    &__text {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 200%);
      font-weight: bold;
    }
  }

  &.fetching .loading-overlay {
    display: block;
  }

  // Desktop
  @include media-breakpoint-up(lg) {
    margin-left: $sidebar_width_xl;
  }
}

@keyframes rotate {
  0% {
    transform: scaleX(0);
  }

  25% {
    transform: scaleX(-1);
    background: $cs_blue;
  }

  75% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}
