@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.front-page--password-reset {
	&__back {
		display: block;
		text-decoration: none;
		color: $gray-400;
		background: none;
		margin-top: 40px;

		&:hover {
			text-decoration: underline;
		}

		@include media-breakpoint-up(sm) {
			text-align: right;
		}
	}
}
