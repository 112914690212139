@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.input-text {
  display: block;

  .label {
    display: block;
    margin-bottom: 7px;
  }

  input {
    padding: 15px;
    font-size: 14px;
    line-height: 1;
    height: auto;
    width: 100%;
    transition: background;

    &:placeholder {
      color: #80808f;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: #000;

      // -webkit-box-shadow: 0 0 0px 1px #000 inset;
      // transition: background-color 1s ease-in-out 0s;
    }
  }

  .inline-error {
    font-size: 14px;
    padding-top: 8px;
    font-weight: 600;
    display: block;
    color: $danger;
  }
}

// "required" asterisk, used by multiple components.
span.required {
  color: $danger;
}

.label {
  font-size: 14px;
  font-weight: 700;
}
