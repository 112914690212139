@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.contact-details {
	@include make-row();
	border-bottom: 1px solid $gray-200;
	padding: 20px 0;
	// text-align: center;

	&__inner {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		max-width: $front-page-content-max-width;
		margin-right: auto;
		margin-left: auto;
		padding: 0 ($grid-gutter-width * 0.5);
	}

	a {
		align-items: center;

		@include make-col-ready();
		@include make-col(12);

		color: $cs_blue;
		font-weight: 600;
		font-size: 15px;

		&:first-child {
			margin-bottom: 10px;

			@include media-breakpoint-up(sm) {
				margin-bottom: 0;
			}
		}

		@include media-breakpoint-up(sm) {
			display: flex;
			flex: 0 0 50%;
		}

		&::before {
			margin-top: 3px;
			margin-right: 5px;
		}
	}
}
