@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.sub-heading-section {
	padding: 10px 0 20px 0;
	border-bottom: 1px solid $cs_grey_border;

	@include media-breakpoint-up(sm) {
		padding: 20px 0 40px 0;
	}

	&__right {
		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}

	.btn-primary {
		border-radius: 99px;
	}
}
