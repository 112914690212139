@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.find-customer {
  &__form {
    padding: 30px 0 0 0;
    margin: 0;

    &__heading {
      display: inline-block;
      margin: 0 30px 0 0;
    }

    &__shell {
      border-radius: 3px;
      display: inline-block;

      > label {
        width: 100%;
        margin: 0;
        padding: 0;
        display: inline-block;

        @include media-breakpoint-up(md) {
          width: auto;
          margin-right: 20px;
        }
      }

      input,
      select {
        border: 1px solid $gray-200;
        border-radius: 0;
        margin-right: 10px;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      select {
        min-width: 188px;
        color: $cs_blue;
        font-weight: 600;
        text-align: center;
        background-color: $gray-200;
      }
    }
  }

  &__loading {
    color: $cs_blue;
    font-weight: 800;
    margin-top: 50px;
    text-align: center;
    font-size: 22px;
  }

  &__results {
    .table {
      &__row {
        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;

          &__cell:not(:last-child) {
            margin-bottom: 10px;
          }

          .load {
            flex-basis: 100%;
          }
        }
      }
    }

    .icon-download:before {
      margin-right: 10px;
      line-height: 1;
    }
  }
}
