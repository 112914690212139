@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/sass/DataTable.scss';

.cart {
  .table {
    .table__row {
      color: $cs_blue_alt;
    }
  }

  [class*='icon-cardssafe'] {
    &:before {
      font-size: 28px;
      padding-right: 10px;
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      color: $primary;

      @include media-breakpoint-up(sm) {
        font-size: 35px;
        padding-right: 15px;
      }
    }
  }

  .data-table &__discount {
    &--label {
      background: darken($success, 10%);
      color: #fff;
    }

    &--amount {
      background: darken($success, 20%);
      color: #fff;
    }
  }

  &__coupon {
    margin-top: 20px;
    background: $gray-200;
    padding: 15px 40px;
    position: relative;

    &__input {
      background: none;
      display: block;
      border: none;
      padding: 0 0 10px 0;
      border-radius: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      border-bottom: 1px solid #e2e2e2;
      width: 100%;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #a1a1a1;
      }
    }

    .btn {
      position: absolute;
      top: 10px;
      right: 40px;
      font-size: 13px;
      font-weight: 600;
      color: #7b7b7b;
      background: #e2e2e2;
      border-radius: 20px;
      padding: 6px 15px;
      line-height: 1;
    }

    &__notice {
      font-weight: 600;
      font-size: 14px;
      padding-top: 10px;
      margin: 0;
    }
  }

  // .icon-key {
  // }

  // .icon-box {
  // }

  // .icon-unit {
  // }

  .remove {
    > button {
      border: none;
      background: none;
      padding: 0;
      color: $danger;
      font-size: 20px;
      line-height: 1;

      &.disabled {
        color: $gray-300;
      }
    }

    &:focus,
    &:active {
      outline: none;
    }

    .sr-only {
      position: absolute;
    }
  }

  &__summary {
    margin-top: 40px;
    width: 100%;

    @include media-breakpoint-up(sm) {
      max-width: 350px;
    }
  }

  &__go-to-checkout {
    text-align: center;
    padding-top: 40px;
  }

  &__empty {
    padding: 40px;
    text-align: center;

    > .icon {
      font-size: 50px;
      color: $cs_blue;
    }
  }
}
