@import '../../../assets/sass/variables.scss';
@import '~bootstrap/scss/mixins';

.add-venue {
	@include media-breakpoint-up(sm) {
		padding-top: 40px;
	}
}

.form-section {
	padding: 40px 0;
	border-top: 1px solid $gray-100;

	&:first-of-type {
		// padding-top: 0;
		border-top: none;
	}

	.col > p {
		margin-bottom: 20px;
	}

	// Tablet and below
	@include media-breakpoint-down(sm) {
		.btn,
		.btn-primary,
		.btn-secondary {
			width: 100%;
		}
	}
}
