@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.dashboard {
	&__intro {
		display: inline-block;
		// margin-left: 40px;
		margin-bottom: 0;
	}

	&__bar {
		padding: 30px 0 20px 0;

		@include media-breakpoint-up(sm) {
			padding: 60px 0 40px 0;
		}

		&__switch {
			.label {
				display: inline-block;
				width: auto;
				margin-right: 15px;
			}

			.input-select > select {
				display: inline-block;
				width: auto;
			}
		}
	}

	// The main grid of units.
	&__units {
		@include make_row();
	}

	.add-new-unit {
		background: #fff;
		box-shadow: 0 10px 24px 0 rgba(#2d2f87, 0.07);
		display: flex;
		min-height: 200px;

		a {
			display: block;
			color: $cs_blue;
			font-weight: 800;

			&:hover {
				text-decoration: none;
				color: $primary;
			}
		}

		i[class^='icon'] {
			font-size: 20px;
			margin-right: 10px;
			vertical-align: middle;
		}
	}
}
