@import '../../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.product-page {
  padding-top: 40px;

  @include media-breakpoint-up(sm) {
    padding-top: 60px;
  }

  .usp {
    padding-top: 30px;
    padding-bottom: 0px;

    @include media-breakpoint-up(sm) {
      padding-top: $grid-gutter-width * 2;
      padding-bottom: 40px;
    }

    &__single {
      color: $gray-400;
      font-size: 10px;
      line-height: 1.3;
      text-align: center;
      font-weight: 600;
      position: relative;
      padding-left: 15px;
      padding-right: 15px;

      @include media-breakpoint-up(sm) {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 12px;
      }

      i[class^='icon'] {
        font-size: 35px;
        line-height: 1;
        display: block;
        margin-bottom: 15px;

        @include media-breakpoint-up(sm) {
          font-size: 50px;
        }
      }

      &:after {
        position: absolute;
        content: '';
        border-right: 1px solid transparentize($gray-400, 0.8);
        height: 50px;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        display: none;

        @include media-breakpoint-up(sm) {
          display: block;
        }
      }

      &:first-child {
        @include media-breakpoint-up(sm) {
          padding-left: $grid-gutter-width * 0.5;
        }
      }

      &:last-child {
        @include media-breakpoint-up(sm) {
          padding-right: $grid-gutter-width * 0.5;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}

.product-details {
  &__heading {
    font-size: 24px;
    margin-bottom: 40px;
  }

  &__description {
    font-size: 15px;
    line-height: 26px;
    color: $gray-400;

    @include media-breakpoint-up(sm) {
      font-size: 17px;
      line-height: 30px;
    }
  }

  &__price-table {
    margin-top: 20px;
    color: $cs_blue;
    font-size: 15px;

    dl {
      @include make-row();

      margin: 0;
    }

    dt,
    dd {
      @include make-col(12);
      margin: 0;
      padding: 10px 0px;
      text-align: center;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
        padding: 10px 30px;
      }

      &:nth-of-type(even) {
        background: $gray-200;
      }
    }

    dd {
      transition: background 0.2s;
      padding-top: 0;

      @include media-breakpoint-up(sm) {
        padding: 10px 30px;
      }
    }

    dt {
      font-weight: 800;
    }

    .has-cap {
      padding-top: 10px;
      background: $success;
      color: #fff;

      @include media-breakpoint-up(sm) {
        padding: 10px 30px;
      }
    }
  }

  &__has-cap-text {
    font-weight: 800;
    text-align: center;
    color: $success;
    margin-top: 30px;
  }

  .input-number {
    margin-top: 40px;

    label {
      font-weight: 800;
    }
  }

  &__next {
    margin-top: 20px;
  }
}
