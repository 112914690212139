@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.menu-toggle {
	$menu-toggle-width: 25px;
	$menu-toggle-bar-height: 3px;
	$menu-toggle-bar-spacing: 4px;
	$menu-toggle-height: (($menu-toggle-bar-height * 3) + ($menu-toggle-bar-spacing * 2));
	$menu-toggle-bar-transform: ($menu-toggle-bar-height + $menu-toggle-bar-spacing);

	position: absolute;
	left: ($grid-gutter-width * 0.5);
	width: $menu-toggle-width;
	height: $menu-toggle-height;
	background-color: transparent;
	border: 0;
	cursor: pointer;

	&,
	&:active,
	&:focus {
		outline: 0;
	}

	.bar {
		position: absolute;
		top: $menu-toggle-bar-transform;
		display: block;
		width: $menu-toggle-width;
		height: $menu-toggle-bar-height;
		background-color: $white;
		border-radius: 3px;
		transition: transform 0.3s;

		&__1 {
			transform: translateY(($menu-toggle-bar-transform * -1));
		}

		&__3 {
			transform: translateY($menu-toggle-bar-transform);
		}
	}

	// Toggled styles
	.menu-toggled & {
		.bar {
			&__1 {
				transform: rotate(45deg) translate(0, 0);
			}

			&__2 {
				transform: scale(0);
			}

			&__3 {
				transform: rotate(-45deg) translate(0, 0);
			}
		}
	}

	// Desktop
	@include media-breakpoint-up(lg) {
		display: none;
	}
}
