@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~Payment-Methods-SVG/style/all.cards.scss';

.checkout {
	&__address,
	&__payment {
		padding-top: 40px;
	}

	&__step-heading {
		font-size: 22px;
		font-weight: 600;
		margin-bottom: 30px;
	}

	&__step-instructions {
		font-size: 17px;
	}

	&__form {
		margin-top: 50px;

		&__heading {
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 30px;
		}
	}

	&__bottom-navigation {
		margin-top: 30px;
	}

	/* Payment method step. */
	&__payment__method {
		margin-top: 30px;
		width: auto;
	}

	&__payment__note {
		display: block;
		font-size: 12px;
		font-weight: 600;
		margin-top: 10px;
		color: $gray-300;
	}

	&__payment__gateway-logos {
		font-size: 40px;

		.paymentMethod {
			margin: 20px 20px 0 0;
		}
	}
}

// Order status pages.
.order-details {
	@include make-col-ready();
	@include media-breakpoint-up(lg) {
		@include make-col(6);
		@include make-col-offset(3);
		padding-top: 60px;
	}

	&__back {
		@include make-col-ready();

		@include media-breakpoint-up(md) {
			@include make-col(3);
		}
	}

	&__heading {
		@include make-col-ready();

		@include media-breakpoint-up(md) {
			@include make-col(6);
			text-align: center;
			margin-bottom: 40px;
		}
	}

	&__data {
		border-top: 1px solid $gray-200;
		padding-top: $grid-gutter-width;
		margin: 0;
		font-size: 14px;
		color: $gray-300;

		// The <dl>
		&__list {
			margin-top: $grid-gutter-width;
			margin-left: 0;
			margin-right: 0;

			dt,
			dd {
				@include make-col(6);
				line-height: 1.25;
				padding: 20px;
				padding-bottom: calc(20px - 0.75em);
			}

			dt {
				color: $cs_blue;
				font-weight: 600;
				background: $gray-200;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 20px;
					right: 20px;
					border-top: 1px solid transparentize(#80808f, 0.8);
				}

				&:first-of-type:before {
					border-top: none;
				}
			}

			dd {
				margin-top: 1px;
				// padding-left: 40px;

				@include media-breakpoint-up(sm) {
					padding-left: 40px;
				}
			}
		}

		.tooltip-wrapper {
			margin-right: 10px;
		}
	}
}
