//////////
// Grid //
//////////

// The main breakpoints.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Min height for "large" screens.
$screen-height-lg: 700px;

///////////
// Fonts //
///////////

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-sans-serif-2: 'Lato', sans-serif;

$font-family-base: $font-family-sans-serif !default;
$headings-font-family: $font-family-sans-serif-2;

$headings-font-weight: 900;
$headings-line-height: 1.5;

$h1-font-size: 26px;
$h2-font-size: 18px;
$h3-font-size: 16px;
$h4-font-size: 15px;
$h5-font-size: 15px;
$h6-font-size: 15px;

////////////
// Colors //
////////////

// The page background colour.
$body-bg: #fff;

// The main purple colour.
$primary: #aa359f;

// Main blue colour for text.
$cs_blue: #2f398e;
$cs_blue_alt: #332e89;

$gray-100: #e6e6e9;
$gray-200: #f5f5f8;
$gray-300: #9a9aa6;
$gray-400: #80808f;

// Main border colour.
$cs_grey_border: transparentize($gray-300, 0.8);

// All headings.
$headings-color: $cs_blue;

// These 3 colours are used for the gradient.
$cs_gradient_1: #aa359f;
$cs_gradient_2: #862c9e;
$cs_gradient_3: #232e84;

// $success: #36e0ac;
$success: #0ab457;
$danger: #ff6357;

/////////////
// Buttons //
/////////////

$input-btn-padding-y-lg: 10px;
$input-btn-padding-x-lg: 40px;
$input-btn-font-size-lg: 1rem;
$input-btn-line-height-lg: 1;

// $input-btn-border-width: 0;

$btn-border-radius: 100px;
$btn-border-width: 0;

/////////////
// Inputs  //
/////////////

$input-border-color: #ececef;

/////////////
// Sidebar //
/////////////

// Widths at different breakpoints.
$sidebar_width_xl: 236px;
$sidebar-bottom-height: 180px;
$sidebar-bottom-margin-top: 60px;
$modal-content-border-radius: 0;
$sidebar-z-index: 7777;

////////////
// Header //
////////////

$header_height: 68px;

///////////
// Modal //
///////////

$modal-lg: 630px;

//////////
// User //
//////////

// Background color for basic avatar.
$avatar_background_colour: #c0c0d3;

//////////
// Front-page
//////////
$front-page-content-max-width: 482px;
