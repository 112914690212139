@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.order-history {
  &__loading {
    color: $cs_blue;
    font-weight: 800;
    margin-top: 50px;
    text-align: center;
    font-size: 18px;
  }

  .key {
    font-size: 0.9em;
    line-height: 1;

    &-warning {
      > span {
        background-color: $warning;
      }
    }

    &-colour {
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      height: 1em;
      width: 1em;
      margin-right: 10px;
    }
  }

  .table__row.pending {
    border: 2px solid $warning;
  }
}
