@import '../../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.confirm-modal {
	position: fixed;
	top: 20px;
	left: 20px;
	right: 20px;

	z-index: 9999;
	/*border: 1px solid;*/
	/*padding: 20px;*/
	background-color: #fff;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.35);

	@include media-breakpoint-up(sm) {
	}

	@include media-breakpoint-up(md) {
		width: 600px;
		top: 50px;
		left: 50%;
		margin-left: -300px;
	}

	&__heading {
		height: 80px;
		margin-bottom: 0;
		line-height: 80px;
		color: $white;
		box-shadow: 1px 4px 11px rgba(0, 0, 0, 0.1);
		@include gradient-x-three-colors($cs_gradient_1, $cs_gradient_3, 50%, $cs_gradient_3);
		// background: $danger;
	}

	// i[class^='icon'] {
	// 	font-size: 40px;
	// 	display: block;
	// 	line-height: 1;
	// 	margin-bottom: 15px;
	// 	color: $danger;
	// }

	&__body {
		padding: 20px 20px;

		@include media-breakpoint-up(sm) {
			padding: 50px 60px;
		}
	}

	&__actions {
		margin-top: 30px;
		margin-right: -5px;
		margin-left: -5px;

		@include media-breakpoint-up(sm) {
			margin-top: 50px;
		}

		.btn {
			min-width: 180px;
			padding: 10px 40px;
			margin: 5px;
		}
	}

	.content-wrap {
		.icon {
			display: block;
			font-size: 100px;
			line-height: 0;
			color: #ffcc4d;
		}

		.divider {
			width: 100%;
			height: 1px;
			margin-top: 36px;
			margin-bottom: 36px;
			border-bottom: 1px solid transparentize($black, 0.9);
		}

		.title {
			font-size: 20px;
			margin-bottom: 10px;
		}

		.description {
			font-size: 14px;
			color: #80808f;
		}
	}

	.modal-dialog & {
		// left: 0;
		// width: 100%;
	}

	@include media-breakpoint-up(md) {
		// left: 25px;

		&__actions {
			.btn {
				min-width: 200px;
				padding: 10px 40px;
				margin: 15px;
			}
		}
	}
}
