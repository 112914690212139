@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.error-message-block {
	margin: $grid-gutter-width 0;
	padding: 20px;
	border: 1px solid darken($danger, 10%);
	background: $danger;
	color: #fff;
	border-radius: 3px;

	&__link {
		color: #fff;
		text-decoration: none;

		&:hover {
			cursor: pointer;
			color: #fff;
			text-decoration: none;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li {
		margin: 15px 0 0 0;
	}

	.icon {
		margin-right: 6px;
		line-height: 1;
		font-size: 1.2em;
		vertical-align: middle;
	}
}
