@import '../../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.key {
  &__illustration {
    padding: 0;

    @include media-breakpoint-up(sm) {
      padding: 40px 0 40px 0;
    }

    @media screen and (min-height: $screen-height-lg) {
      padding: 50px 0 50px 0;
    }

    > svg {
      width: 100%;
      max-width: 700px;
    }
  }

  &__disclaimer {
    @include make-col-ready();
    text-align: left;

    @include media-breakpoint-up(sm) {
      @include make-col(6);
      @include make-col-offset(3);
    }
  }

  &__inputs {
    @include make-row();
    text-align: left;

    .form-field {
      @include make-col-ready();
      margin: 0 0 20px 0;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
        @include make-col-offset(3);
        margin-bottom: 10px;
      }

      &.help-text {
        margin-bottom: 15px;

        > i {
          font-size: 0.9em;
          margin-right: 5px;
        }
      }

      .label {
        color: $cs_blue;
        font-weight: 600;
        font-size: 15px;
        width: 100%;
        padding-right: 0;
        display: inline-block;
        vertical-align: middle;

        @include media-breakpoint-up(sm) {
          width: 160px;
          padding-right: 40px;
        }

        .required {
          display: inline;
        }
      }

      input {
        width: 100%;
        display: block;

        @include media-breakpoint-up(sm) {
          width: calc(100% - 160px);
          display: inline-block;
        }
      }

      .inline-notice {
        display: block;
        color: #6f6f6f;
        font-size: 12px;
        padding-top: 20px;
      }
    }
  }
}
