.tooltip-wrapper {
	position: relative;
	cursor: pointer;

	> span {
		outline: none;
	}
}

.tooltip {
	position: absolute;
	z-index: 8888;
	top: 100%;
	margin-top: 20px;
	left: 0;
	background-color: #fff;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
	opacity: 1;
	padding: 10px;
	width: 160px;
	line-height: 1;
	text-align: center;

	> i {
		font-size: 26px;
		vertical-align: middle;
	}
}
