@import '../../../assets/sass/variables.scss';
@import '~bootstrap/scss/mixins';

.about-the-venue {
	padding-top: 30px;

	@include media-breakpoint-up(sm) {
		padding-top: 100px;
	}
}
