@import '../../assets/sass/variables.scss';

.input-address {
	// margin-bottom: 30px;
	&__heading {
		margin-bottom: 15px;
	}
	&__lookup {
		padding: 30px 15px 05px 15px;
		background: $gray-200;
		margin-bottom: 30px;
		position: relative;

		&:after {
			position: absolute;
			bottom: -15px;
			left: 50%;
			transform: translate(-15px, 0) rotate(45deg);
			content: '';
			background: $gray-200;
			width: 30px;
			height: 30px;
		}
	}

	select {
		position: relative;
		z-index: 10;
	}

	.btn {
		margin-top: 10px;
	}
}
