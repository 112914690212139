@import '../../assets/sass/variables.scss';
// @import '../../assets/font/cardssafe-icons.css';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.button-group {
  $parent: &;

  margin: 15px 0 30px 0;

  &__single {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;

    &.has-icon {
      text-align: center;
      width: 70px;
    }
  }

  input:checked {
    & + label {
      color: $cs_blue;
    }
  }

  input {
    margin-right: 5px;
  }

  .has-icon {
    input {
      display: none;

      + label {
        font-size: 12px;
        line-height: 1.2;
        display: block;
        min-height: 70px;
      }

      &:checked {
        & + label {
          font-weight: 600;

          &:before {
            background: $cs_blue;
            color: #fff;
          }
        }
      }
    }

    [class^='icon-']:before {
      display: block;
      // color: #fff;
      font-size: 45px;
      line-height: 70px;
      background: $gray-200;
      width: 70px;
      height: 70px;
      margin-bottom: 10px;
      transition: all 0.15s linear;

      font-family: 'cardssafe' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  label {
    &:hover {
      cursor: pointer;
    }
  }

  label::selection {
    background: transparent; /* WebKit/Blink Browsers */
  }
  label::-moz-selection {
    background: transparent; /* Gecko Browsers */
  }

  .inline-error {
    font-size: 14px;
    padding: 2px 0 8px 0;
    font-weight: 600;
    display: block;
    color: $danger;
  }
}
