@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.sub-navigation-bar {
  border-bottom: 1px solid $cs_grey_border;
  padding: 10px 0;

  &__header {
    font-weight: 600;
    color: $cs_blue;
    margin-right: 20px;
  }

  &__item {
    display: inline-block;
    color: #80808f;
    padding: 10px 20px;

    &.active {
      background: #f5f5f8;
      border-radius: 30px;
      color: $cs_blue;
    }

    &.warning {
      color: $danger;
    }
  }
}
