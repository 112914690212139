@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.input-number {
	label {
		color: $cs_blue;
		font-weight: 600;
	}

	input {
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&__control {
		display: inline-block;
		vertical-align: middle;
		margin: 10px 0 0 0;
		border: 1px solid $gray-200;
		border-radius: 3px;
		overflow: hidden;

		@include media-breakpoint-up(sm) {
			margin: 0 0 0 20px;
		}
	}

	&__plus,
	&__minus {
		display: inline-block;
		font-weight: 600;
		font-size: 18px;
		color: $cs_blue;
		text-align: center;
		background: none;
		border: none;
		outline: none;
		width: 50px;
		min-height: 42px;

		&:focus {
			outline: none;
		}

		&:active {
			outline: none;
			background: $gray-100;
		}
	}

	.form-control {
		display: inline-block;
		width: 114px;
		min-height: 42px;
		background: #fff;
		color: $cs_blue;
		font-weight: 600;
		font-size: 14px;
		text-align: center;
		border: none;
		outline: none;
		box-shadow: none;
		border-left: 1px solid $gray-200;
		border-right: 1px solid $gray-200;
	}
}
