@import '../../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.unit-options {
	padding-top: 40px;

	@include media-breakpoint-up(sm) {
		padding-top: 100px;
	}
}

