@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.notice-modal {
	display: block;
	width: auto;
	position: fixed;
	top: 20px;
	right: 20px;
	left: 20px;
	z-index: 9999;
	font-weight: 600;

	@include media-breakpoint-up(sm) {
		width: 500px;
		top: 30px;
		right: 30px;
		left: auto;
	}

	.notice {
		margin: 0;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);

		button {
			background: transparent;
			border: 2px solid #fff;
			font-size: 14px;
			color: #fff;
			font-weight: 600;
			margin-left: 15px;
			border-radius: 2px;
		}
	}

	.notice--success {
		&:before {
			font-family: 'cardssafe' !important;
			font-style: normal !important;
			font-weight: normal !important;
			font-variant: normal !important;
			text-transform: none !important;
			speak: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			content: '\47'; // icon-check
			vertical-align: middle;
			margin-right: 15px;
			font-size: 20px;
		}
	}

	.notice--failure,
	.notice--error {
		background: $danger;

		&:before {
			font-family: 'cardssafe' !important;
			font-style: normal !important;
			font-weight: normal !important;
			font-variant: normal !important;
			text-transform: none !important;
			speak: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			content: '\76'; // icon-check
			vertical-align: middle;
			margin-right: 15px;
			font-size: 20px;
		}
	}
}
