@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.summary {
	margin: 20px 0 0 0;
	background: #fff;
	box-shadow: 0 10px 24px 0 rgba(#2d2f87, 0.07);

	@include media-breakpoint-up(sm) {
		margin: 0 0 0 auto;
		max-width: 273px;
	}

	&__heading {
		background: $gray-200;
		font-size: 15px;
		padding: 20px;
		text-align: center;
	}

	// A single item in the summary section.
	&__row {
		@include make-row();
		align-items: flex-start;

		font-size: 12px;
		color: $cs_blue;
		margin: 20px;
		padding-bottom: 10px;
		border-bottom: 1px solid $gray-200;

		@include media-breakpoint-up(sm) {
			margin: 30px;
			padding-bottom: 20px;
		}

		// &:first-of-type {
		// 	border-top: none;
		// 	margin-top: 40px;
		// 	padding-top: 0;
		// }

		&__icon {
			padding-top: 5px;
			font-size: 25px;
			line-height: 1;
			color: $primary;
		}

		&__description {
			padding-left: 10px;
		}

		&__variant {
			color: #666672;
		}

		&__remove {
			border: none;
			background: none;
			padding: 0;
			color: $gray-400;
			font-size: 16px;
			line-height: 1;
			margin-left: auto;

			&:focus,
			&:active {
				outline: none;
			}
		}
	}

	&__empty {
		font-size: 14px;
		padding: 30px;
		color: $cs_blue;
		font-weight: 600;
	}

	&__postage {
		font-size: 14px;
		color: $cs_blue;
		padding: 0 30px 30px 30px;
		.label {
			font-weight: 600;
		}
	}

	&__total {
		background: $gray-200;
		font-size: 14px;
		color: $cs_blue;
		padding: 15px 30px;

		.label {
			font-weight: 600;
		}
	}

	// Bottom section with buttons and other actions.
	&__add-to-cart {
		padding: 30px;

		.btn {
			display: block;
			width: 100%;
		}
	}
}
