@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

/**
 * Styling to be used on the login, register and password reset views.
 */
.front-page {
	&__title {
		font-size: $h1-font-size;
		font-weight: 800;
		margin-bottom: 20px;

		@include media-breakpoint-up(sm) {
			font-size: 32px;
		}
	}

	&__description {
		font-size: 17px;
		color: $gray-400;
	}

	&__content {
		max-width: $front-page-content-max-width;
		padding-top: 40px;
		padding-bottom: 40px;

		@include media-breakpoint-up(sm) {
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}

	.label {
		display: none;
	}

	input {
		border: none;
		border-bottom: 1px solid $gray-100;
		padding-left: 0;
		padding-right: 0;
		border-radius: 0;
		font-size: 17px;
		// text-indent: 10px;

		&:focus {
			outline: none;
			box-shadow: none;
		}

		&::placeholder {
			color: rgba($gray-300, 0.8);
		}

		&:-internal-autofill-selected {
			background: #fff;
		}
	}

	.btn-primary {
		@include gradient-x-three-colors($cs_gradient_1, $cs_gradient_2, 50%, $cs_gradient_3);
		color: #fff;
		border-radius: 100px;
		border: none;
		margin-top: 50px;
		font-size: 13px;
		font-weight: 600;
		line-height: 20px;
		padding: 10px;
		min-width: 200px;

		&-alt {
			@include gradient-directional(#aa359f, #7c0371, 45deg);
		}
	}

	// Tablet
	@include media-breakpoint-up(md) {
		.btn-primary {
			font-size: 14px;
		}
	}

	// Desktop
	@include media-breakpoint-up(lg) {
		.btn-primary {
			padding: 20px;
			font-size: 15px;
		}
	}
}

/**
 * Buttons
 */
.btn-hollow {
	padding: 10px 40px;
	line-height: 1;
	color: $primary;
	border-radius: 99px;
	border: 1px solid $primary;
	transition: all 0.1s;
	font-size: 14px;
	font-weight: 600;

	&:before {
		vertical-align: middle;
		margin-right: 10px;
		font-size: 14px;
	}

	&:hover {
		color: #fff;
		background: $primary;
	}
}
