@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.table__row {
	@include make-row();
	margin-left: 0;
	margin-right: 0;

	background: #fff;
	box-shadow: 0 10px 24px 0 rgba(#2d2f87, 0.07);
	padding: 0;
	margin-top: 20px;
	// display: flex;
	font-size: 14px;

	@include media-breakpoint-up(sm) {
		padding: 15px 0;
	}

	// @include media-breakpoint-down(sm) {
	// 		@include make-col(12);
	// 	}

	&__cell {
		@include make-col-ready();
		// @include make-col(6);
		// @include make-col-offset(6);

		position: relative;

		@include media-breakpoint-down(sm) {
			padding-top: 15px;
			padding-bottom: 15px;
			@include make-col(6);
			@include make-col-offset(6);
		}

		&:before {
			padding: 0 15px;
			width: 100%;
			position: absolute;
			left: 0;
			text-align: left;
			transform: translate(-100%, 0);
			content: attr(data-label);
			font-weight: 600;
			color: $cs_blue;
			font-size: 15px;
			word-break: break-all;
			line-height: 1.2;
			display: none;

			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
	}

	.icon {
		display: inline-block;
		vertical-align: middle;
		// width: 15px;
		// height: 15px;
		// background: $primary;
		// border-radius: 50%;
		margin-right: 10px;
	}

	.small-col {
		width: auto;
	}
}
