@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.user-profile {
	&__intro-block {
		// padding-top: 60px;
	}
}

.form {
	@include make-row();
	padding-top: 30px;

	> p {
		@include make-col-ready();
		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}
	}

	// Tablet and below
	@include media-breakpoint-down(sm) {
		.btn,
		.btn-primary,
		.btn-secondary {
			width: 100%;
		}
	}

	.input-password__meter {
		top: 39px;
		right: 10px;
	}
}
