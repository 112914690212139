@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.modal-open {
	.modal {
		display: block;
		opacity: 1;
	}
}

.comment-modal {
	z-index: 9999;
	background: rgba(#000, 0.6);

	.modal-header {
		@include gradient-x-three-colors(
			$cs_gradient_1,
			$cs_gradient_3,
			50%,
			$cs_gradient_3
		);
		color: #fff;
		border: none;
		padding: 30px 20px;
		text-align: center;
		position: relative;

		.modal-title {
			color: #fff;
			font-size: $h1-font-size;
			line-height: 1;
			display: block;
			text-align: center;
			font-weight: 600;

			span {
				display: inline-block;
				vertical-align: middle;
				font-size: 15px;
				font-weight: 400;

				&:first-of-type {
					margin-left: 10px;
					margin-right: 10px;
					font-size: $h1-font-size;
					font-weight: 800;
				}
			}
		}
	}

	.modal-title {
		width: 100%;
	}

	.modal-content {
		box-shadow: 0 0 30px rgba(#000, 0.9);
		border: none;
	}

	.modal-body {
		padding: 30px;
	}

	.close {
		color: #fff;
		opacity: 1;
		position: absolute;
		z-index: 10;
		top: 30px;
		right: 30px;
	}

	&__author-avatar {
		background: $avatar_background_colour;
		width: 49px;
		height: 49px;
		color: #fff;
		font-size: 14px;
		font-weight: 600;
		border-radius: 50%;
		line-height: 49px;
		text-align: center;
		display: inline-block;
	}

	&__author-name {
		color: $cs_blue;
		font-weight: 600;
	}

	&__time {
	}

	&__comment {
		font-size: 14px;
		color: $gray-400;
		line-height: 30px;
	}

	&__content {
		border-top: 1px solid rgba(#000, 0.1);
		padding-top: 20px;
		margin-top: 20px;
		padding-bottom: 50px;
	}

	&__notice {
		display: block;
		padding: 20px;
		text-align: center;
		background: $primary;
		color: #fff;
		margin-bottom: 30px;
	}

	&__delete {
		border: none;
		background: none;
		padding: 0;
		color: $danger;
		font-size: 20px;
		line-height: 1;

		&:focus,
		&:active {
			outline: none;
		}
	}

	&__form {
		.btn {
			margin-top: 10px;
			padding: 10px 20px;
		}
	}
}
