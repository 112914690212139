@import '../../assets/sass/variables.scss';

.input-checkbox {
	outline: none;

	// . {
	// 	outline: none;
	// }

	+ .inline-notice {
		display: inline-block;
		margin-left: 10px;
		color: $cs_blue;
		font-weight: 800;
	}
}
