// @use "sass:math";

@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.welcome-board {
  color: #fff;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  @include media-breakpoint-up(sm) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include gradient-x-three-colors(
    $cs_gradient_1,
    $cs_gradient_2,
    50%,
    $cs_gradient_3
  );

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: url('../../assets/svg/main_wave_1.svg') bottom left no-repeat,
      url('../../assets/svg/main_wave_2.svg') bottom left no-repeat,
      url('../../assets/svg/main_wave_3.svg') bottom left no-repeat;
    background-size: 100% auto;
  }

  .logo {
    svg {
      width: 152px;
      height: auto;
    }

    padding-bottom: 20px;

    @include media-breakpoint-up(sm) {
      padding-bottom: 40px;
    }
  }

  &__content {
    width: 100%;
    // padding: $grid-gutter-width;
    max-width: 500px;
    position: relative;
    z-index: 10;
  }

  &__title {
    padding-bottom: 10px;
    font-size: $h1-font-size;
    color: #fff;
    font-weight: 600;

    @include media-breakpoint-up(sm) {
      font-size: 30px;
    }
  }

  .usp-block {
    margin-top: 30px;

    > div {
      margin-top: 10px;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }

    &__usp {
      height: 100%;
      background: rgba(#fff, 0.1);
      padding: 10px;
      border-radius: 3px;
      text-align: center;

      @include media-breakpoint-up(sm) {
        padding: 20px;
      }

      i[class^='icon'] {
        font-size: 50px;
        line-height: 1;
        display: inline-block;

        &:before {
          line-height: 1;
        }

        @include media-breakpoint-up(sm) {
          display: block;
        }
      }

      p {
        margin-top: 12px;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 1.5;
        font-weight: 800;
        color: #fff;
      }
    }
  }

  &__actions {
    margin-top: 30px;
    margin-left: $grid-gutter-width / -2;
    margin-right: $grid-gutter-width / -2;
    text-align: center;
  }

  .btn-white {
    border: 1px solid #fff;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    padding: 8px 10px;
    min-width: 160px;
    background: transparent;
    transition: all 0.3s linear;
    border-radius: 100px;

    margin: 5px;

    &.active,
    &:hover {
      background: #fff;
      color: $cs_blue;
    }

    &:hover {
      box-shadow: 0 0 30px 0 rgba(#fff, 0.5);
    }
  }

  .stars {
    > span {
      position: absolute;
      z-index: 2;
      display: block;
      background: #fff;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      animation-name: stars;
      animation-duration: 9s;
      animation-iteration-count: infinite;
      animation-timing: linear;
    }

    span {
      &:nth-of-type(0) {
        top: 42%;
        left: 66%;
        opacity: 0.12;
      }

      &:nth-of-type(1) {
        top: 10%;
        left: 23%;
        opacity: 0.1;
        animation-duration: 5s;
        animation-delay: 1s;
      }

      &:nth-of-type(2) {
        top: 30%;
        left: 55%;
        opacity: 0.2;
        animation-name: stars-2;
      }

      &:nth-of-type(3) {
        top: 60%;
        left: 43%;
        opacity: 0.15;
        animation-duration: 4s;
      }

      &:nth-of-type(4) {
        top: 80%;
        left: 80%;
        opacity: 0.18;
        animation-name: stars-2;
      }

      &:nth-of-type(5) {
        top: 76%;
        left: 44%;
        opacity: 0.1;
        animation-duration: 6s;
      }

      &:nth-of-type(6) {
        top: 58%;
        left: 9%;
        opacity: 0.1;
        animation-name: stars-2;
      }
    }
  }

  // Tablet
  @include media-breakpoint-up(md) {
    &__title {
      font-size: 34px;
    }

    &__actions {
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;
    }

    .btn-white {
      min-width: 180px;
      font-size: 14px;

      margin: 0 15px;
    }
  }

  // Desktop
  @include media-breakpoint-up(lg) {
    min-height: 100vh;

    .logo {
      padding-bottom: 60px;

      svg {
        width: 176px;
      }
    }

    &__title {
      font-size: 45px;
      margin-bottom: 40px;
    }

    .usp-block {
      margin-top: 60px;
    }

    &__actions {
      margin-top: 40px;
    }

    .btn-white {
      min-width: 200px;
      font-size: 15px;
    }
  }
}

@keyframes stars {
  25% {
    transform: translate(0, -10px);
  }

  50% {
    transform: translate(0, 10px) scale(0.6);
  }

  75% {
    transform: translate(0, -10px);
  }

  0%,
  100% {
    transform: translate(0, 0);
  }
}

@keyframes stars-2 {
  25% {
    transform: translate(0, -30px);
  }

  50% {
    transform: translate(0, 30px) scale(0.2);
  }

  75% {
    transform: translate(0, -30px);
  }

  0%,
  100% {
    transform: translate(0, 0);
  }
}
