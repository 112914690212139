@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.dashboard__welcome {
	// transform: translate(0, -40px);

	&__heading {
		font-size: 38px;
		font-weight: 900;
		margin-bottom: 30px;
	}

	p {
		color: $gray-400;
		font-size: 17px;
		line-height: 30px;
		margin-bottom: 40px;
	}

	.btn {
		min-width: 200px;
		font-weight: 600;
		line-height: 2em;
	}

	.dashboard__bar__switch {
		margin-top: 40px;

		.label {
			color: $cs_blue;
			font-weight: 600;
		}

		select.form-control {
			width: 50%;
		}
	}
}

.dashboard__unit {
	text-align: center;

	@include media-breakpoint-up(sm) {
		transform: translate(0, -40px);
		text-align: right;
		padding-right: 40px;
	}

	> svg {
		max-width: 100%;
		max-height: 180px;

		@include media-breakpoint-up(sm) {
			max-height: 100%;
			height: 355px;
		}
	}
}
