@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.btn {
  &:disabled,
  &.back {
    background: $gray-800;
    border-color: $gray-800;
  }

  &.hollow {
    border: 2px solid $gray-800;
    background: transparent;
    color: $gray-800;
  }
}

.btn-primary {
  @include gradient-x-three-colors(
    $cs_gradient_1,
    $cs_gradient_3,
    50%,
    $cs_gradient_3
  );

  padding: 10px 40px;
  color: #fff;
  transition: all 0.4s linear;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    background: $gray-800;
    border-color: $gray-800;
  }
}

.btn-secondary {
  @include gradient-x(#aa359f, #7c0371);
  padding: 10px 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 800;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    background: $gray-800;
    border-color: $gray-800;
  }
}

.btn-warning,
.btn-danger {
  background: $warning;
  padding: 10px 40px;
  color: #fff;
  transition: all 0.4s linear;

  &:hover {
    text-decoration: underline;
  }
}

.btn-danger {
  background: $danger;
}
