@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.order-replacement-parts {
	&__select-draws {
		padding-top: 30px;

		@include media-breakpoint-up(sm) {
			padding-top: 60px;
		}
	}

	sub {
		bottom: 0.5em;
	}

	&__unit {
		text-align: center;
	}

	.large-unit-svg {
		margin-bottom: 30px;
	}
}
