@import '../sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Global style for data tables. This is a mock table which uses a <dl> element.
.data-table {
	margin: 0;

	dt,
	dd {
		@include make-col(6);
		margin: 0;
		padding: 15px 40px;
		color: $cs_blue;

		&:nth-of-type(odd) {
			background: $gray-200;
		}
	}

	dd {
		text-align: right;
	}
}
