@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Add header height to css variable for javascript
:root {
  --header-height: #{$header_height};
}

.sidebar {
  position: absolute;
  z-index: $sidebar-z-index;
  padding: 0;
  margin: 0;
  max-width: $sidebar_width_xl;
  width: $sidebar_width_xl;
  box-shadow: 1px 0 11px 0 transparentize(#000, 0.9);
  min-height: calc(100vh - #{$header_height});
  background-color: $white;
  transform: translateX(-110%);
  transition: transform 0.3s;

  &__inner {
    height: 100%;
    padding: 0 0 ($sidebar-bottom-height + $sidebar-bottom-margin-top);
  }

  .user {
    background: $gray-200;
    text-align: center;
    padding: 20px 10px;

    @include media-breakpoint-up(md) {
      padding: 40px 10px 20px 10px;
    }

    &__avatar {
      background-color: $avatar_background_colour;
      margin-bottom: 30px;
      border-radius: 50%;
      color: #fff;
      width: 82px;
      height: 82px;
      line-height: 82px;
      display: none;

      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }

    &__name {
      text-align: left;
      display: block;
      background: #fff;
      padding: 10px;
      color: $cs_blue;
    }

    .venue-switcher {
      margin-top: 20px;
      label {
        text-align: left;
      }

      .form-control {
        width: 100%;
      }
    }
  }

  .menu-toggled & {
    transform: translateX(0);
  }

  &.affix {
    position: fixed;
    bottom: 0;
  }

  // Desktop
  @include media-breakpoint-up(lg) {
    // position: relative;
    transform: translateX(0);
  }
}
