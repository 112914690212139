@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.mini-cart {
	.icon-shopping-cart {
		color: $cs_blue;
		line-height: 1;

		&:hover {
			text-decoration: none;
			color: $primary;
		}

		&:before {
			font-size: 20px;
			line-height: 1;
			vertical-align: middle;
			margin-right: 15px;
		}
	}
}
