@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.stage-navigation {
	// @include make-row();
	border-radius: 20px;
	overflow: hidden;
	box-shadow: 0 10px 21px 0 rgba(#000, 0.1);
	max-width: 620px;
	margin-left: auto;
	margin: auto;
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	line-height: 36px;

	@include media-breakpoint-up(sm) {
		border-radius: 30px;
	}

	.col {
		@include media-breakpoint-down(sm) {
			@include make-col(12);
		}
	}

	a {
		// @include make-col-ready();
		background: $gray-200;
		color: #afafb9;

		&:hover,
		&.active {
			background: $success;
			cursor: pointer;
			text-decoration: none;
			color: #fff;
		}

		// $parent.children-2 & {
		// 	width: 50%;
		// }
	}
}
