@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.pagination {
	margin-top: 40px;

	.page-link {
		border-color: $gray-100;

		&.active {
			background: $primary;
			color: #fff;
		}
	}
}
