@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.box-data {
	&__title {
		font-size: $h2-font-size;
		margin-bottom: 15px;
	}

	&__contract-number {
		color: $cs_blue;
		margin-bottom: 15px;

		@include media-breakpoint-up(sm) {
			text-align: right;
			font-size: 16px;
		}
	}

	&__keys-numbers {
		padding-top: 20px;
		border-top: 1px solid $gray-200;
		color: $gray-400;
		font-size: 16px;
	}

	&__replacement-keys {
		background: $gray-200;
		padding: 18px;
		margin-top: 30px;
		font-size: 13px;
		color: #6f6f6f;

		i {
			font-size: 15px;
			margin-right: 12px;
			line-height: 1;
			vertical-align: middle;
		}
	}

	&__options {
		&__option {
			@include make_row();
			// margin-right: 0;
			align-items: center;
			margin-top: 20px;
		}

		&__draw {
			@include make-col-ready();
			@include make-col(12);
			padding-bottom: 10px;
			font-weight: 800;
			font-size: 15px;
			color: $cs_blue;

			@include media-breakpoint-up(xxl) {
				@include make-col(2);
				padding-bottom: 0;
			}
		}

		&__config {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(xxl) {
				@include make-col(5);
			}
		}

		&__label {
			display: block;
			box-shadow: 0 5px 7px 0 rgba(#2d2f87, 0.07);
			padding: 15px 40px;
			line-height: 1;
			position: relative;
			color: $cs_blue;
			cursor: pointer;
			text-align: center;
			// min-width: 154px;

			@include media-breakpoint-up(xxl) {
				display: inline-block;
				min-width: 154px;
			}

			&:before {
				font-size: 24px;
				vertical-align: middle;
				line-height: 1;
				margin-right: 10px;
			}

			&:after {
				font-family: 'cardssafe' !important;
				font-style: normal !important;
				font-weight: normal !important;
				font-variant: normal !important;
				text-transform: none !important;
				speak: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: '\47';

				position: absolute;
				top: -10px;
				right: -10px;
				background: $success;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				z-index: 10;
				display: none;
				font-size: 11px;
				line-height: 20px;
				color: #fff;
			}

			.active & {
				background: $gray-200;
				font-weight: 600;
			}

			.active &:after {
				display: block;
			}
		}
	}

	&.active {
		background-color: red;
	}
}
