@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.notice {
  padding: 20px;
  color: #fff;
  margin-top: 20px;
  border-radius: $border-radius;
  text-align: center;
  background: $cs_blue;

  // max-width: 300px;

  &--failure {
    background: $danger;
  }

  &--success {
    background: $success;
  }
}
