@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/mixins';

.business-industry {
  display: block;
  margin-bottom: 30px;

  &-prompt {
    font-size: 14px;
    line-height: 1.2;
    margin-top: 20px;
    padding: 8px 14px;
    color: #fff;
    background-color: $primary;
  }

  .label {
    display: block;
    margin-bottom: 7px;
  }
}
