@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.header-sticky {
  min-height: $header_height;
}

.header {
  @include gradient-x-three-colors(
    $cs_gradient_1,
    $cs_gradient_2,
    50%,
    $cs_gradient_3
  );

  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  &.fetching {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingAnimation;
    animation-timing-function: linear;
    background: $cs_gradient_1;
    @include gradient-x-three-colors(
      $cs_gradient_1,
      $cs_gradient_3,
      50%,
      $cs_gradient_1
    );
    background-size: 1000px 100%;

    // height: 96px;
    // position: relative;
  }

  &__inner {
    min-height: $header_height;
  }

  a {
    color: #fff;
  }

  .logo {
    flex-grow: 0;

    svg {
      height: 38px;
      width: auto;
    }
  }

  &__contact-details {
    display: none;
  }

  // Desktop
  @include media-breakpoint-up(lg) {
    .logo {
      padding-left: 40px;

      svg {
        height: 41px;
        width: auto;
      }
    }

    &__contact-details {
      display: block;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      padding: 10px 30px;
      margin-left: auto;
      line-height: 1;

      a {
        padding: 0 30px;

        &:hover {
          text-decoration: none;
        }

        &:before {
          line-height: 1;
          vertical-align: middle;
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

@keyframes loadingAnimation {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
