.switch-page-enter {
	opacity: 0;
}

.switch-page-enter-active {
	opacity: 1;
	transition: opacity 500ms;
}

.switch-page-exit {
	opacity: 1;
}

.switch-page-exit-active {
	opacity: 0;
	transition: opacity 500ms;
}
