@import '../../assets/sass/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.table {
	width: 100%;
	border: none;

	&__head {
		padding: 15px 0;
		margin-top: 15px;
		display: flex;
		color: $cs_blue;
		font-weight: 800;
		font-size: 16px;
		// display: none;

		&__column {
			@include make-col-ready();
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}
